import React, { Component } from 'react';
import { Button, Form, Header, Grid, Divider } from 'semantic-ui-react';
import Testimonial from '../../testimonials/Testimonial';

export default class ExistingListingsEditor extends Component {
  state = {
    testimonials: [],
  };

  componentDidMount() {
    this.refreshTestimonials();
  }

  refreshTestimonials = async () => {
    const collection = await this.props.firestore.collection('testimonials').get();

    const testimonials = collection.docs.map((item) => ({
      id: item.id,
      data: item.data(),
    }));

    this.setState({ testimonials: testimonials })
  }

  submitChanges = async (e, id) => {
    const { testimonials } = this.state;
    
    const itemIndex = testimonials.findIndex((item) => item.id === id);
    testimonials[itemIndex].loading = true;

    const itemInfo = testimonials[itemIndex];
    const { src, buyers, review } = itemInfo.data;
    
    this.setState({ testimonials });

    try {
      await this.props.firestore.collection('testimonials').doc(id).set({ src, buyers, review });
    } catch(e) {
      console.log(e);
    }

    testimonials[itemIndex].loading = false;
    this.setState({testimonials});
  }

  edit = (event, id, key) => {
    const { testimonials } = this.state;
    const listingIndex = testimonials.findIndex((item) => item.id === id);
    testimonials[listingIndex].data[key] = event.target.value;
    this.setState({ testimonials });
  }

  deleteListing = async (e, id) => {
    const { testimonials } = this.state;
    const listingIndex = testimonials.findIndex((home) => home.id === id);
    testimonials[listingIndex].deleteLoading = true;
    this.setState({ testimonials });

    try {
      await this.props.firestore.collection('testimonials').doc(id).delete();
      await this.refreshTestimonials();
    } catch(e) {
      console.log(e);
    }
  }

  render() {
    const { testimonials } = this.state;
    const count = testimonials.length;
    if (count === 0) {
      return (
        <div>
          No testimonials have been added. Create a new one using the tab above.
        </div>
      )
    }
    return (
      testimonials.map((item, index) => this.getEditor(item, index, count))
    )
  }

  getEditor = (itemInfo, index, count) => {
    const { id, data, loading, deleteLoading } = itemInfo;
    const { src, buyers, review } = data;
    return (
      <div key={id}>
        <div>
          <Header
            as='h3'
            content={'Testionial ' + (index + 1) + " of " + count}
            style={{ marginTop: '1em' }}
          />
          <Form onSubmit={(e) => this.submitChanges(e, id)}>
            <Form.Field>
              <label>Image URL</label>
              <input
                value={src}
                onChange={(e) => this.edit(e, id, 'src')}
              />
            </Form.Field>
            <Form.Field>
              <label>Name(s)</label>
              <input
                value={buyers}
                onChange={(e) => this.edit(e, id, 'buyers')}
              />
            </Form.Field>
            <Form.Field>
              <label>Review</label>
              <textarea
                value={review}
                onChange={(e) => this.edit(e, id, 'review')}
              />
            </Form.Field>
            <Grid verticalAlign={'middle'} centered style={{ marginBottom: '1em' }}>
              <Testimonial mobile={false} testimonial={data} />
            </Grid>
            <div style={{ textAlign: 'center' }}>
              <Button type='submit' primary loading={loading}>Submit changes to listing</Button>
              <Button loading={deleteLoading} type="button" negative onClick={(e) => this.deleteListing(e, id)}>Delete listing</Button>
            </div>
          </Form>
        </div>
        <Divider />
      </div>
    )
  }
}