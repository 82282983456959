import React from 'react';
import { Header, Divider, Container, Responsive } from 'semantic-ui-react';
import ContactForm from '../contact/ContactForm.js';

const paralax = {
    backgroundImage: 'url("./assets/paralax.jpg")',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative'
};

const ContactFormSection = () => ( 
    <div>
        <Responsive minWidth={768}>
            <div style={paralax}> 
                <div style={{background:'rgba(0,0,0,0.5)', paddingTop: '5em', paddingBottom: '5.5em'}}>
                    <Container>                
                        <Header as={'h1'} textAlign={'center'} className='white'>Let's Talk Real Estate!</Header>
                        <Header as={'h3'} textAlign={'center'} className='white' >Fill out the form below with any questions you have and we will make sure to answer all your real estate questions. </Header>
                        <Divider hidden />
                        <ContactForm size='large' buttonSize='huge' formWidth='60%' inverted='true' />
                    </Container>
                </div>
            </div>
        </Responsive>
        <Responsive maxWidth={767}>
            <div style={{paddingTop: '2em', paddingBottom: '2.5em'}}>
                <Container>                
                    <Header as={'h1'} textAlign={'center'}>Let's Talk Real Estate!</Header>
                    <Header as={'h3'} textAlign={'center'}>Fill out the form below with any questions you have and we will make sure to answer all your real estate questions.</Header>
                    <Divider hidden />
                    <ContactForm size='small' buttonSize='large' formWidth='90%' inverted='false'/>
                </Container>
            </div>       
        </Responsive> 
    </div>    
);

export default ContactFormSection;
