import React from 'react';
import { Container, Image, Icon } from 'semantic-ui-react';

const MainHeader = () => (
    <div id="mainHeader">
      <Container>
        <Image src='./assets/logo.png' className='logo' verticalAlign='middle'/>
        <div className='header-container center'>
          <Image rounded src='./assets/carol_headshot.JPG' verticalAlign='top' className='headshot'/> 
          <div className='contact-info-container'>      
            <a className="contact-info" href="tel:19207020186"><Icon name='phone' flipped={'horizontally'} color={'green'}/> (920) 702-0186</a>
            <a className="contact-info" href="mailto:Carol.Shinykey@gmail.com"><Icon name='mail' color={'green'}/> Carol.Shinykey@gmail.com</a>
            <a className='float-left' href="https://www.facebook.com/Shiny-Key-Realty-LLC-1474006076065649/" target="_blank" rel="noopener noreferrer">
              <Icon name='facebook f' color={'green'}/>
            </a>
          </div>
        </div>
      </Container>
    </div>
);


export default MainHeader;
