import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Segment, Header, Container, Button, Responsive} from 'semantic-ui-react';
import { Link } from "react-router-dom";

const settings = {
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed:10000,
    arrows: false,
    dots: true,
    pauseOnHover: false
  };

const testimonialsData = [
    {
        buyers: 'Barb',
        review: `Thank you again for all you have done for me over the years- I really couldn't have done it without you`
    },
    {
        buyers: 'Ashley & Steven',
        review: `Thank you so much for helping us in the purchase of our first home! You went above and beyond to help 
        us in this very exciting time in our lives, and we couldn't have done it without you!`
    },
    {
        buyers: 'Wanda & Bob',
        review: `Carol was a pleasure to work with as our Real Estate agent. She assisted us in selling our Business along with 
        the purchase of our new home. Carol was with us all the way, through the process...`
    }
]
const TestimonialSection = () => (
    <div>
        <Responsive minWidth={768}>
            <div style={{backgroundImage: 'url(./assets/testimonials.jpg)'}}>
                <Container style={{paddingTop:'4em', paddingBottom: '4.5em'}}>
                    <Segment padded='very' raised style={{background: 'rgba(33, 186, 69, .9)'}}>
                        <div style={{border: 'solid 1px white', padding: '4em 4em 6em 4em'}}>
                            <Header as={'h1'} content={'Testimonials'} textAlign={'center'} className='white'/>
                            <Slider {...settings}>
                                {testimonialsData.map((testimonial, i) => (
                                    <div style={{marginTop: '1em'}} key={i}>
                                        <Header as={'h3'}
                                        content={testimonial.review} 
                                        className='white' 
                                        key={i}/>
                                        <Header as={'h3'} textAlign={'center'} content={'-' + testimonial.buyers} className='white' style={{fontStyle: 'italic', marginTop: '0'}} />
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className='center' style={{marginTop: '2em'}}>
                            <Button size='huge' inverted as={Link} to={'/testimonials'}>View All Testimonials</Button>
                        </div>
                    </Segment>
                </Container>
            </div> 
        </Responsive>
        <Responsive maxWidth={767}>
            <div style={{backgroundImage: 'url(./assets/testimonials.jpg)'}}>
                <Container style={{paddingTop:'2em', paddingBottom: '2.5em'}}>
                    <Segment raised style={{background: 'rgba(33, 186, 69, .9)'}}>
                        <div style={{border: 'solid 1px white', padding: '2em 2em 5em'}}>
                            <Header as={'h1'} content={'Testimonials'} textAlign={'center'} className='white'/>
                            <Slider {...settings}>
                                {testimonialsData.map((testimonial, i) => (
                                    <div style={{marginRight: '3em', marginLeft:'3em'}} key={i}>
                                        <Header as={'h3'}
                                        content={testimonial.review} 
                                        className='white'/>
                                        <Header as={'h3'} textAlign={'center'} content={'-' + testimonial.buyers} className='white' style={{fontStyle: 'italic', marginTop: '0'}}/>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className='center' style={{marginTop: '1em'}}>
                            <Button size='large' inverted as={Link} to={'/testimonials'}>View All Testimonials</Button>
                        </div>
                    </Segment>
                </Container>
            </div> 
        </Responsive>
    </div>  
);

export default TestimonialSection;
