import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import ExistingAgentEditor from './ExistingAgentEditor';
import NewAgentEditor from './NewAgentEditor';

export default class AgentEditor extends Component {
  panes = [
    { 
      menuItem: 'Edit existing agents', 
      render: () => (
        <Tab.Pane>
          {<ExistingAgentEditor firestore={this.props.firestore} />}
        </Tab.Pane>
      ) 
    },
    {
      menuItem: 'Add new agent',
      render: () => (
        <Tab.Pane>
          {<NewAgentEditor firestore={this.props.firestore} />}
        </Tab.Pane>
      )
    },
  ]

  render() {
    return (
      <div style={{padding: '2em 4em'}}>
        <Tab panes={this.panes} />
      </div>
    )
  }
}
