import React from 'react';
import { Header, Button, Divider, Responsive, Container } from 'semantic-ui-react';
import { Link } from "react-router-dom";

const Section3 = () => (
    <div className='section3'>
        <Container>
            <Header as={'h1'} textAlign={'center'}>Curious about the value of your home?</Header>
            <Header as={'h3'} textAlign={'center'}>We’re here to help if you have any questions at all.</Header>
            <Divider hidden />
    
            <div style={{textAlign: 'center'}}>
                <Responsive minWidth={768}>
                    <Button size='huge' as={Link} to={'/contact'} color={'green'}>Free Market Analysis</Button>
                </Responsive>
                <Responsive maxWidth={767}>
                    <Button size='large' as={Link} to={'/contact'} color={'green'}>Free Market Analysis</Button>
                </Responsive>
            </div>
        </Container>
    </div>
);

export default Section3;
