import React, { Component } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import FeaturedHomeEditor from './FeaturedHomeEditor/FeaturedHomeEditor';
import AdminNavigation from './AdminNavigation';
import { useLocation } from "react-router-dom";
import TestimonialEditor from './TestimonialEditor/TestimonialEditor';
import AgentEditor from './AgentEditor/AgentEditor';


const AdminRouter = ({ firestore, auth }) => {
  const { pathname } = useLocation();
  const adminPage = pathname.split('/')[2];
  const pageSet = new Set(['featured-home-editor', 'testimonial-editor', 'agent-editor']);
  return (
    <div>
      <AdminNavigation bgcolor={'green'} auth={auth} />
      {!pageSet.has(adminPage) && (
        <h2 style={{ textAlign: 'center' }}>Select an editor above.</h2>
      )}
      {adminPage === 'featured-home-editor' && <FeaturedHomeEditor firestore={firestore} />}
      {adminPage === 'testimonial-editor' && <TestimonialEditor firestore={firestore} />}
      {adminPage === 'agent-editor' && <AgentEditor firestore={firestore} />}
    </div>
  );
}

class Admin extends Component {
  state = {
    username: '',
    password: '',
    authenticated: false,
  };

  componentDidMount() {
    this.getAuthStatus();
  }

  getAuthStatus = async () => {
    const adminCollection = await this.props.firestore.collection('admins').get();
    const adminList = new Set(adminCollection.docs.map((admin) => admin.id));

    this.props.auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        const { uid } = currentUser;
        this.setState({ authenticated: adminList.has(uid) })
      }
    });
  }

  editUsername = (event) => {
    this.setState({username: event.target.value});
  }

  editPassword = (event) => {
    this.setState({password: event.target.value});
  }

  login = () => {
    const { username, password } = this.state;

    this.props.auth.signInWithEmailAndPassword(username, password).catch((error) => {
      var errorMessage = error.message;
      this.setState({ errorMessage })
    });
  }


  render() {
    const { authenticated } = this.state;
    const { firestore, auth } = this.props;
  
    if (authenticated) {
      return <AdminRouter firestore={firestore} auth={auth} />
    }
    return (
      <Form
        style={{padding: '2em 4em'}}
        onSubmit={this.login}
      >
        <Form.Field width={6}>
          <label>Username</label>
          <input
            placeholder='Enter your admin username'
            value={this.state.username}
            onChange={this.editUsername}
          />
        </Form.Field>
        <Form.Field width={6}>
          <label>Password</label>
          <input
            placeholder='Enter your password'
            value={this.state.password}
            type="password"
            onChange={this.editPassword}
          />
        </Form.Field>
        <Button type='submit' primary>Submit</Button>
        {this.state.errorMessage && (
          <Message
            header={"Failed to login"}
            content={this.state.errorMessage}
            color='red'
          />
        )}
      </Form>
    );
  }
};

export default Admin;