import React from 'react';
import { Header, Grid, Icon, Responsive } from 'semantic-ui-react';
import ContactForm from './ContactForm.js';

const backgroundStylesDesktop = {
    background: 'linear-gradient(180deg, rgba(249, 249, 255), 80%, rgba(249, 249, 255, 0)), url("./assets/appleton.png")',
    height: '100%'
}
const backgroundStylesMobile = {
    background: 'linear-gradient(180deg, rgba(249, 249, 255), 70%, rgba(249, 249, 255, 0)), url("./assets/appleton.png")',
    height: '100%'
}

const Contact = () => (
    <div>
        <Responsive minWidth={768}>
            <Grid padded style={{height: '650px'}}>
                <Grid.Column width={8} style={backgroundStylesDesktop}>
                    <div style={{width: '80%', margin: '9em auto'}}>
                        <Header as={'h1'} content={"We're here to help"}/>
                        <p style={{fontSize: '1.5em', marginBottom: '3em'}}>
                        Our door is always open to assist you in the buying or selling of your home.
                        </p>
                        <Header as={'h2'} content={"Our Office"}/>
                        <p style={{fontSize: '1.5em',  marginBottom: '.25em'}}>
                            Shiny Key Realty, LLC
                        </p>
                        <div style={{fontSize: '1.5em', marginBottom: '.5em'}}>
                            <a href="mailto:Carol.Shinykey@gmail.com" style={{color: '#3c424f'}}>
                                Carol.Shinykey@gmail.com
                            </a>
                        </div>
                        <div style={{fontSize: '1.5em', marginBottom: '.5em'}}>
                            <a href="tel:19207020186" style={{color: '#3c424f'}}>
                                (920) 702-0186
                            </a>
                        </div>
                        <a className='float-left' href="https://www.facebook.com/Shiny-Key-Realty-LLC-1474006076065649/" target="_blank" rel="noopener noreferrer">
                            <Icon name='facebook f' size={'large'} color={'green'}/>
                        </a>
                    </div>
                </Grid.Column>
                <Grid.Column width={8} style={{background: 'rgba(33, 186, 69)'}}>  
                    <div style={{width: '80%', margin: '9em auto'}}>
                        <Header as={'h1'} content={"Let's chat."} className='white'/>
                        <ContactForm size='large' buttonSize='large' inverted='true' />
                    </div>
                </Grid.Column>
            </Grid> 
        </Responsive>
        <Responsive maxWidth={767}>
            <Grid padded verticalAlign={'middle'}>
                <Grid.Row style={{paddingBottom: '0', paddingTop: '0', marginTop: '-1.5em'}}>
                    <Grid.Column style={backgroundStylesMobile}>
                        <div style={{marginTop: '3em', marginBottom: '2em'}}>
                            <Header as={'h1'} content={"We're here to help"}/>
                            <p style={{fontSize: '1.5em'}}>
                                Our door is always open to assist you in the buying or selling of your home.
                            </p>
                            <Header as={'h3'} content={"Our Office"}/>
                            <p style={{fontSize: '1.5em',  marginBottom: '.25em'}}>
                                Shiny Key Realty, LLC
                            </p>
                            <p style={{fontSize: '1.5em', marginBottom: '.25em'}}>
                                Carol.Shinykey@gmail.com
                            </p>
                            <p style={{fontSize: '1.5em'}}>
                                (920) 702-0186
                            </p>
                            <Icon name='facebook f' size={'large'} color={'green'}/>
                            <Icon name='instagram' size={'large'} color={'green'}/>   
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{paddingBottom: '0', paddingTop: '0'}}>
                    <Grid.Column style={{background: 'rgba(33, 186, 69)'}}>  
                        <div style={{width: '90%', margin: '2em auto'}}>
                            <Header as={'h1'} content={"Let's chat."} className='white'/>
                            <ContactForm size='small' buttonSize='large' inverted='true' />
                        </div>
                    </Grid.Column>
                </Grid.Row>
            </Grid> 
        </Responsive>
    </div>
    );

export default Contact;
