import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import ExistingListingEditor from './ExistingListingsEditor';
import NewListingEditor from './NewListingEditor';

export default class FeaturedHomeEditor extends Component {
  panes = [
    { 
      menuItem: 'Edit existing listings', 
      render: () => (
        <Tab.Pane>
          {<ExistingListingEditor firestore={this.props.firestore} />}
        </Tab.Pane>
      ) 
    },
    {
      menuItem: 'Add new listing',
      render: () => (
        <Tab.Pane>
          {<NewListingEditor firestore={this.props.firestore} />}
        </Tab.Pane>
      )
    },
  ]

  render() {
    return (
      <div style={{padding: '2em 4em'}}>
        <Tab panes={this.panes} />
      </div>
    )
  }
}
