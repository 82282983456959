import React from 'react';
import { Header, Embed, Container } from 'semantic-ui-react';

const Section2 = () => (
    <Container style={{marginTop: '2.5em', marginBottom: '2.5em', paddingBottom: '1em'}}>
        <Header as={'h1'} textAlign={'center'} content={'Meet Shiny Key Realty'} />
        <Header as={'h3'} content={'Give us a call today for FREE market analysis.'}
            textAlign={'center'} style={{marginTop: '0', marginBottom: '1em'}}/>
        <div style={{maxWidth: '600px', margin: '0 auto'}}>
            <Embed
                placeholder='./assets/video_placeholder.png'
                url='./assets/home_video.mp4'
            />
        </div>
    </Container>
);


export default Section2;
