import React, { Component } from "react";
import { Container, Grid, Responsive } from "semantic-ui-react";
import Agent from "./Agent";

class Agents extends Component {
  state = {
    agentsData: [],
  };

  async componentDidMount() {
    const agents = await this.props.firestore.collection("agents").get();
    this.setState({ agentsData: agents.docs.map((item) => item.data()) });
  }

  render() {
    const { agentsData } = this.state;
    return (
      <div>
        <Responsive minWidth={768}>
          <Container style={{ paddingTop: "2em", marginBottom: "2em" }}>
            <Grid verticalAlign={"middle"} centered>
              {agentsData.map((agent, i) => (
                <Agent key={i} agent={agent} mobile={false} />
              ))}
            </Grid>
          </Container>
        </Responsive>
        <Responsive maxWidth={767}>
          <Container style={{ marginBottom: "2em" }}>
            <Grid verticalAlign={"middle"} centered>
              {agentsData.map((agent, i) => (
                <Agent key={i} agent={agent} mobile={true} />
              ))}
            </Grid>
          </Container>
        </Responsive>
      </div>
    );
  }
}

export default Agents;
