import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Header, Button, Divider, Responsive } from 'semantic-ui-react';
import { Link } from "react-router-dom";

const settings = {
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 3000,
  autoplaySpeed: 2000,
  arrows: false,
  pauseOnHover: false
};

const slides = [
  { src: './assets/slider1.jpg', alt: 'carouselImage1' },
  { src: './assets/slider2.jpg', alt: 'carouselImage2' },
  { src: './assets/slider3.jpg', alt: 'carouselImage3' },
]

const Section1 = () => ( 
    <div>
        <Responsive minWidth={768}>
          <div className="section1Content">
            <div className="section1HeaderContainer">
              <Header as={'h1'} textAlign={'center'} className='white'>YOUR DREAM HOME AWAITS</Header>
              <Header as={'h3'} textAlign={'center'} className='white'>Let's find a home you love.</Header>
              <Divider hidden />
              <div className='center'>
                <Button size='large' as={Link} to={'/featured-homes'} color={'green'}>Featured Homes</Button>
                <Button size='large' as={Link} to={'/buyers'}>Search Homes</Button>
              </div>
            </div>
          </div>
          <Slider {...settings}>
            {slides.map((slide, i) => (
              <div className='slide' key={i}>
                <img style={{opacity: '.5'}} src={slide.src} alt={slide.alt}/>
              </div>
            ))}
          </Slider>
      </Responsive>
      <Responsive maxWidth={767}>
        <div className="section1Content">
            <div className="section1HeaderContainer">
              <Header as={'h1'} textAlign={'center'} className='white'>YOUR DREAM HOME AWAITS</Header>
              <Header as={'h3'} textAlign={'center'} className='white'>Let's find a home you love.</Header>
              <Divider hidden />
              <div className='center'>
                <Button size='large' as={Link} to={'/featured-homes'} color={'green'}>Featured Homes</Button>
                <Button size='large' as={Link} to={'/buyers'} >Search Homes</Button>
              </div>
            </div>
        </div>
      </Responsive>
    </div>
);

export default Section1;
