import React, { Component } from 'react';
import { Form, Divider, Message, Transition } from 'semantic-ui-react';

class ContactForm extends Component {

    state = { 
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        formSubmitted: false,
        invalidEmail: false
    }

    handleChange = (e, { name, value }) => this.setState({ [name]: value })

    handleSubmit = e => {

        var re = /^.+@.+\..+$/;
        if(re.test(String(this.state.email).toLowerCase())){        
            const data = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phone,
                message: this.state.message
            }

            fetch('/send', {
                method: "POST",
                headers: {
                'Content-type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            
            this.setState({ firstName: '', lastName: '', email: '', phone: '', message: '', formSubmitted: true, invalidEmail: false });

            setTimeout(() => this.setState({formSubmitted: false}), 3000);
        }
        else{
            this.setState({invalidEmail: true})
            setTimeout(() => this.setState({invalidEmail: false}), 3000);
        }
    };


    render() {

        const { firstName, lastName, email, phone, message, formSubmitted, invalidEmail } = this.state

        return (
            <div style={{position: 'relative'}}>
            <Form 
                name='contact'
                inverted={(this.props.inverted === 'true')}
                size={this.props.size} 
                style={{width: this.props.formWidth, margin: '0 auto',}}
                onSubmit={this.handleSubmit}
           >
                <Form.Group>
                    <input type='hidden' name='form-name' value='contact' />
                    <Form.Input
                        width={8}
                        required
                        id='form-first-name'
                        placeholder='First name'
                        name='firstName'
                        value={firstName}
                        onChange={this.handleChange}
                    />
                    <Form.Input
                        width={8}
                        required
                        id='form-last-name'
                        placeholder='Last name'
                        name='lastName'
                        value={lastName}
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Input
                        width={8}
                        required
                        id='form-first-name'
                        placeholder='Phone number'
                        name='phone'
                        value={phone}
                        onChange={this.handleChange}
                    />
                    <Form.Input
                        width={8}
                        required
                        id='form-email-address'
                        placeholder='Email address'
                        name='email'
                        value={email}
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.TextArea
                        width={16}
                        required
                        id='form-message'
                        placeholder='What would you like to discuss?'
                        name='message' 
                        value={message}
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <div style={{textAlign: 'center'}}>
                    <Divider hidden/>
                    <Form.Button type='Submit' size={this.props.buttonSize} inverted={(this.props.inverted === 'true')} labelPosition='right' icon='right chevron' content='Submit' />
                </div>
            </Form>
            <Transition visible={invalidEmail} animation='fade right' duration={500} >
                <div style={{position:'absolute', width: '100%'}}>
                    <Message style={{width: this.props.formWidth, marginTop: '2em', left: '50%', transform: 'translateX(-50%)'}} error header='Invalid Email' content="Please enter a valid email address." />
                </div>
            </Transition>
            <Transition visible={formSubmitted} animation='fade right' duration={500}>
                <div style={{position:'absolute', width: '100%'}}>
                    <Message style={{width: this.props.formWidth, marginTop: '2em', left: '50%', transform: 'translateX(-50%)'}} success header='Message sent!' content="We've received your contact info and will reach out as soon as possible." />
                </div>
            </Transition>
     </div>
        );
    }
}

export default ContactForm;
