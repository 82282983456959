import React from 'react';
import { Header, Image, Grid , Divider } from 'semantic-ui-react';

const Testimonial = ({ testimonial, mobile }) => {
  let imageSrcAttempt;
  try {
    imageSrcAttempt = new URL(testimonial.src);
  } catch (e) {
    console.log("Invalid image src")
  }
  return (
    <Grid.Row>
      <Grid.Column width={mobile ? 14 : 5}>
        {imageSrcAttempt 
          ? <Image src={testimonial.src} size='medium' />
          : <div>Invalid image source</div>
        }
      </Grid.Column>
      <Grid.Column width={mobile ? 14 : 8}>
          <Header as={'h1'} content={testimonial.buyers} style={{ marginTop: mobile ? '1em' : 0 }}/>
          <p style={{fontSize: '1.4em'}}>
              {testimonial.review}
          </p>
      <Divider/>
      </Grid.Column>
    </Grid.Row>
  )
};

export default Testimonial;