import React from 'react';
import { Route, useLocation } from "react-router-dom";
import ScrollToTop from 'react-router-scroll-top';
import { firestore, auth } from './Firebase';

import MainHeader from './components/MainHeader';
import Navigation from './components/Navigation';
import FooterSection from './components/FooterSection';
import Home from './home/Home';
import Testimonials from './testimonials/Testimonials';
import Contact from './contact/Contact';
import Buyers from './buyers/Buyers';
import FeaturedHomes from './featuredHomes/FeaturedHomes';
import Admin from './admin/Admin';
import Agents from './agents/Agents';

const HomePage = () => <Home/>;
const TestimonialsPage = () => <Testimonials firestore={firestore} />;
const AgentsPage = () => <Agents firestore={firestore} />;
const ContactPage = () => <Contact/>;
const BuyersPage = () => <Buyers/>;
const FeaturedHomesPage = () => <FeaturedHomes firestore={firestore} />;

const stickyStyles = {
  position: 'fixed', 
  top: '0', 
  width: '100%',
  zIndex: '3'
};

const App = () => {
  const { pathname } = useLocation();
  if (pathname.includes('/admin')) {
    return <Admin auth={auth} firestore={firestore} />;
  }
  return (
    <ScrollToTop>
      <div style={stickyStyles}>
        <MainHeader/>
        <Navigation bgcolor={'green'}/>
      </div>
      <div id='mainApp'> 
        <Route exact path={"/"} component={HomePage} />
        <Route path="/agents" component={AgentsPage} />
        <Route path="/testimonials" component={TestimonialsPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/buyers" component={BuyersPage} />
        <Route path="/featured-homes" component={FeaturedHomesPage} />
      </div>
      <FooterSection/>  
    </ScrollToTop>
  )
}


export default App;
