import React, { Component } from 'react';
import { Responsive } from 'semantic-ui-react';
import ChildHeader from '../components/ChildHeader';

class Buyers extends Component {
    state = { width: 0 };

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth });
    }

    redirectToMobile = () => {
        window.location.replace('https://app.dsmobileidx.com/1510711/256/');
    }

    formStyles ={
        width: '60%',  
        margin: '0 auto', 
        padding: '5em', 
        background: '#f6f9fc', 
        borderRadius:'5px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    }

    render() {
    if(this.state.width <=768 && this.state.width !== 0){
        this.redirectToMobile()
    }
    return (
      <div>
          <Responsive minWidth={768}>
            <ChildHeader 
                title={`Find a home you'll love`} 
                description='Buying a home can be one of the most exciting times of your life. The experience and knowledge of the Shiny Key team is here to make the home buying process easy. From your first search, until the last box is unpacked, we are here to help you through the process.'
                backgroundSrc='./assets/buyers.jpg' 
            /> 
            <iframe title='mapping-idx' src="https://idx.diversesolutions.com/search/1510711/256" 
                style={{borderStyle: 'none', width:'100%', height: '1000px', background: '#f3f3ea'}}>
                <p>Your browser does not support iframes.</p>
            </iframe>
        </Responsive>
      </div>
      )}
}

export default Buyers;