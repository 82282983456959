
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

var firebaseConfig = {
  apiKey: "AIzaSyBtLScfCAcGuATqkueXim3mT33aM1XUPSE",
  authDomain: "shinykeyrealty-7ff60.firebaseapp.com",
  databaseURL: "https://shinykeyrealty-7ff60.firebaseio.com",
  projectId: "shinykeyrealty-7ff60",
  storageBucket: "shinykeyrealty-7ff60.appspot.com",
  messagingSenderId: "844931961107",
  appId: "1:844931961107:web:279c929aadcb94c2140ae6"
};
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
const auth = firebase.auth();
auth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

export { firestore, auth };