import React from 'react';
import Section1 from './Section1.js';
import Section2 from './Section2.js';
import Section3 from './Section3.js';
import TestimonialSection from './TestimonialSection.js';
import ContactFormSection from './ContactFormSection.js';

const Home = () => (
    <div>
      <Section1/>
      <Section2/>
      <Section3/>
      <ContactFormSection/>
      <TestimonialSection/>
    </div>
);

export default Home;
