import React from 'react';
import { Menu, Responsive, Dropdown } from 'semantic-ui-react';
import { Link } from "react-router-dom";

const Navigation = (props) => (
    <div>
        <Responsive minWidth={768}>
            <Menu className='navigation' widths={6} borderless size={'huge'} color={props.bgcolor} inverted>
                <Menu.Item as={Link} to='/' name='Home'/>
                <Menu.Item as={Link} to='/featured-homes' name='Featured homes'/>
                <Menu.Item as={Link} to='/buyers' name='Buyers'/>
                <Menu.Item as={Link} to='/agents' name='Agents'/>
                <Menu.Item as={Link} to='/testimonials' name='Testimonials'/>
                <Menu.Item as={Link} to='/contact' name='Contact'/>
            </Menu>
        </Responsive>
        <Responsive maxWidth={767}>
            <Menu fluid color={props.bgcolor} inverted style={{borderRadius: 0}}>
                <Dropdown fluid item text='Menu'>
                    <Dropdown.Menu style={{borderRadius: 0}}>
                        <Dropdown.Item as={Link} to='/'>Home</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/featured-homes'>Featured homes</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/buyers'>Buyers</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/agents'>Agents</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/testimonials'>Testimonials</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/contact'>Contact</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu>
        </Responsive>
    </div>
);

export default Navigation;
