import React from "react";
import { Header, Icon } from "semantic-ui-react";

const FooterSection = () => (
  <div
    style={{ background: "#3c424f", paddingTop: "3em", paddingBottom: "3em" }}
  >
    <div style={{ display: "flex", justifyContent: "center" }}>
      <a
        href="https://www.facebook.com/Shiny-Key-Realty-LLC-1474006076065649/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon
          circular
          inverted
          name="facebook f"
          color={"green"}
          size={"big"}
          style={{ marginRight: "1em" }}
        />
      </a>
      <a
        href="https://www.bbb.org/us/wi/appleton/profile/real-estate-broker/shiny-key-realty-0694-1000035947/#sealclick"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://seal-wisconsin.bbb.org/seals/blue-seal-160-82-bbb-1000035947.png"
          style={{ border: 0 }}
          alt="Shiny Key Realty BBB Business Review"
        />
      </a>
    </div>
    <div style={{ marginTop: "1em" }}>
      <Header as={"h4"} textAlign="center" className="white">
        <Icon name="copyright outline" />
        2020 Shiny Key Realty, LLC
      </Header>
    </div>
    <div className="center">
      <Header
        as="h4"
        className="white contact-info-footer"
        style={{ margin: "1em .5em" }}
      >
        <a href="tel:19207020186" className="white">
          <Icon
            name="phone"
            flipped={"horizontally"}
            style={{ display: "inline-block", marginRight: ".5em" }}
          />
          (920) 702-0186
        </a>
        <span
          style={{ marginRight: ".5em", marginLeft: ".5em" }}
          className="hide-mobile"
        >
          |
        </span>
        <div style={{ marginTop: ".5em" }} className="hide-desktop"></div>
        <a href="mailto:Carol.Shinykey@gmail.com" className="white">
          <Icon
            name="mail"
            style={{ display: "inline-block", marginRight: ".5em" }}
          />
          Carol.Shinykey@gmail.com
        </a>
      </Header>
    </div>
    <Header
      as={"h4"}
      textAlign="center"
      className="white"
      style={{ fontSize: "18px", marginTop: ".5em" }}
    >
      Experience | Dedication | Results
    </Header>
  </div>
);

export default FooterSection;
