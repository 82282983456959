import React, { Component } from 'react';
import { Container, Grid, Responsive} from 'semantic-ui-react';
import Testimonial from './Testimonial';
import ChildHeader from '../components/ChildHeader';

class Testimonials extends Component {
    state = {
        testimonialsData: [],
    };
  
    async componentDidMount() {
      const testimonials = await this.props.firestore.collection('testimonials').get();
      this.setState({ testimonialsData: testimonials.docs.map((item) => item.data()) });
    }
  
    render() {
      const { testimonialsData } = this.state;
      return (
        <div>
            <Responsive minWidth={768}>
                <ChildHeader 
                    title='Past client testimonials' 
                    description='Read about what our past clients thought of us.'
                    backgroundSrc='./assets/testimonials.jpg' 
                />
                <Container style={{paddingTop: '2em', marginBottom: '2em'}}>
                    <Grid verticalAlign={'middle'} centered>
                        {testimonialsData.map((testimonial,i) => <Testimonial key={i} testimonial={testimonial} mobile={false} />)}
                    </Grid> 
                </Container>
            </Responsive>
            <Responsive maxWidth={767}>
                <Container style={{marginBottom: '2em'}}>
                    <div style={{marginBottom: '1em'}}>
                        <ChildHeader 
                            title='Past client testimonials' 
                            description='Read about what our past clients thought of us.'
                        />
                    </div>
                    <Grid verticalAlign={'middle'} centered>
                        {testimonialsData.map((testimonial,i) => <Testimonial key={i} testimonial={testimonial} mobile={true} />)}
                    </Grid> 
                </Container>
            </Responsive>
        </div>
      )
    }
}

export default Testimonials;
