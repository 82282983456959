import React, { Component } from 'react';
import { Button, Form, Header, Grid } from 'semantic-ui-react';
import FeaturedHome from '../../featuredHomes/FeaturedHome';

export default class NewListingEditor extends Component {
  state = {
    newListing: {
      imageSrc: '', 
      address: '', 
      cityStateZip: '', 
      price: '', 
      listingUrl: '',
      status: '',
      loading: false,
    },
  };

  submitAddNewListing = async () => {
    const { newListing } = this.state;
    newListing.loading = true;
    this.setState({newListing});

    const { imageSrc, address, cityStateZip, price, listingUrl, status } = newListing;
    try {
      await this.props.firestore.collection('featured_homes').add({
        imageSrc, address, cityStateZip, price, listingUrl, status
      });
    } catch(e) {
      console.log(e);
    }

    this.setState({
      newListing: {
        imageSrc: '', 
        address: '', 
        cityStateZip: '', 
        price: '', 
        listingUrl: '',
        status: '',
        loading: false,
      }
    })
  }

  editNew = (e, key) => {
    const { newListing } = this.state;
    newListing[key] = e.target.value;
    this.setState({ newListing });
  }

  render() {
    const { imageSrc, address, cityStateZip, price, listingUrl, status, loading } = this.state.newListing;
    return (
      <Grid>
        <Grid.Column width={10}>
          <Header
            as='h3'
            content={'New listing'}
          />
          <Form onSubmit={this.submitAddNewListing}>
            <Form.Field>
              <label>Address</label>
              <input
                value={address}
                onChange={(e) => this.editNew(e, 'address')}
              />
            </Form.Field>
            <Form.Field>
              <label>City, State, Zip</label>
              <input
                value={cityStateZip}
                onChange={(e) => this.editNew(e, 'cityStateZip')}
              />
            </Form.Field>
            <Form.Field>
              <label>Price</label>
              <input
                value={price}
                onChange={(e) => this.editNew(e, 'price')}
              />
            </Form.Field>
            <Form.Field>
              <label>Image URL</label>
              <input
                value={imageSrc}
                onChange={(e) => this.editNew(e, 'imageSrc')}
              />
            </Form.Field>
            <Form.Field>
              <label>MLS Listing URL</label>
              <input
                value={listingUrl}
                onChange={(e) => this.editNew(e, 'listingUrl')}
              />
            </Form.Field>
            <Form.Field>
              <label>Status</label>
              <input
                value={status}
                onChange={(e) => this.editNew(e, 'status')}
              />
            </Form.Field>
            <Button type='submit' color='green' loading={loading}>Add new listing</Button>
          </Form>
        </Grid.Column>
        <Grid.Column width={6}>
          <Header
            as='h3'
            content={'How it will show up'}
          />
          <FeaturedHome
            imageSrc={imageSrc}
            price={price}
            address={address}
            listingUrl={listingUrl}
            cityStateZip={cityStateZip}
            status={status}
          />
        </Grid.Column>
      </Grid>
    )
  }
}