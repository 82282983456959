import React from 'react';
import { Menu, Button } from 'semantic-ui-react';
import { Link, useHistory  } from "react-router-dom";

const Navigation = ({ bgcolor, auth }) => {
    let history = useHistory();

    const signOut = () => {
        auth.signOut();
        history.push('/')
    }

    return (
        <Menu className='navigation' widths={5} borderless size={'huge'} color={bgcolor} inverted>
            <Menu.Item as={Link} to='/admin/featured-home-editor' name='Featured homes editor'/>
            <Menu.Item as={Link} to='/admin/agent-editor' name='Agent editor'/>
            <Menu.Item as={Link} to='/admin/testimonial-editor' name='Testimonial editor'/>
            <Menu.Item>
                <Button onClick={() => signOut()}>Log out</Button>
            </Menu.Item>
        </Menu>
)};

export default Navigation;
