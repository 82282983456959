import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import ChildHeader from '../components/ChildHeader';
import FeaturedHome from './FeaturedHome';

class FeaturedHomes extends Component {
  state = {
    featuredHomes: [],
  };

  async componentDidMount() {
    const homeCollection = await this.props.firestore.collection('featured_homes').get();
    // Sort featured homes by status so active listings show first
    this.setState({
      featuredHomes: homeCollection.docs.sort((a, b) =>  (
        a.data().status.localeCompare(b.data().status)
      )),
    })
  }

  render() {
    const { featuredHomes } = this.state;
    return (
      <div>
        <ChildHeader 
          title='Featured Homes' 
          description={`View our personal listings/homes that sellers entrusted us to help them sell.`}
          backgroundSrc='./assets/featured_homes.jpg' 
        />
        <div className="featured-homes">
          {featuredHomes.length > 0 && (
            <Grid stackable centered columns='three'>
              {featuredHomes.map((listing) => {
                const { id } = listing;
                const { imageSrc, price, address, cityStateZip, listingUrl, status } = listing.data();
                return (
                  <Grid.Column key={id}>
                    <FeaturedHome
                      imageSrc={imageSrc}
                      price={price}
                      address={address}
                      listingUrl={listingUrl}
                      cityStateZip={cityStateZip}
                      status={status}
                    />
                  </Grid.Column>
                )
              })}
            </Grid>
          )}
        </div>
      </div>
    )
  }
};

export default FeaturedHomes;