import React from 'react';
import { Header, Responsive } from 'semantic-ui-react';

const ChildHeader = ({title, description, backgroundSrc}) => (
    <div>
        <Responsive minWidth={768}>
            <div style={{height: '400px', backgroundImage: 'url(' + backgroundSrc + ')', backgroundRepeat: 'no-repeat',}}>
                <div  style={{backgroundImage:'-webkit-linear-gradient(-30deg, rgba(33, 186, 69, .6) 45%, rgb(255, 255, 255, 0) 45%)',
                height: '400px', position: 'relative'}}>
                    <div style={{width: '45%', position: 'absolute', top: '50%', transform: 'translateY(-50%)'}}>
                        <Header as={'h1'} 
                            content={title} 
                            className='white'
                            style={{paddingLeft: '1.4em', paddingRight: '1.4em'}}
                            />
                        <Header as={'h3'} 
                            content={description}
                            className='white'
                            style={{paddingLeft: '3em', paddingRight: '3em', marginTop: '0'}}
                        />
                    </div>
                </div>
            </div>
        </Responsive>
        <Responsive maxWidth={768}>
            <div style={{ padding: '1em 2em 0 2em' }}>
                <Header as='h1' content={title} />
                <p>{description}</p>
            </div>
        </Responsive>
    </div>
)

export default ChildHeader;