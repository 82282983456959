import React, { Component } from 'react';
import { Button, Form, Header, Grid } from 'semantic-ui-react';
import Agent from '../../agents/Agent';

export default class newAgentEditor extends Component {
  state = {
    newAgent: {
      src: '', 
      name: '', 
      bio: '',
      loading: false,
    },
  };

  submitAddnewAgent = async () => {
    const { newAgent } = this.state;
    newAgent.loading = true;
    this.setState({newAgent});

    const { src, name, bio } = newAgent;
    try {
      await this.props.firestore.collection('agents').add({ src, name, bio });
    } catch(e) {
      console.log(e);
    }

    this.setState({
      newAgent: {
        src: '', 
        name: '', 
        bio: '',
        loading: false,
      }
    })
  }

  editNew = (e, key) => {
    const { newAgent } = this.state;
    newAgent[key] = e.target.value;
    this.setState({ newAgent });
  }

  render() {
    const { src, name, bio, loading } = this.state.newAgent;
    return (
      <div>
        <Header
          as='h3'
          content={'New agent'}
        />
        <Form onSubmit={this.submitAddnewAgent}>
          <Form.Field>
            <label>Image URL</label>
            <input
              value={src}
              onChange={(e) => this.editNew(e, 'src')}
            />
          </Form.Field>
          <Form.Field>
            <label>Name</label>
            <input
              value={name}
              onChange={(e) => this.editNew(e, 'name')}
            />
          </Form.Field>
          <Form.Field>
            <label>Bio</label>
            <textarea
              value={bio}
              onChange={(e) => this.editNew(e, 'bio')}
            />
          </Form.Field>
          <Header
            as='h3'
            content={'How it will show up'}
          />
          <Grid verticalAlign={'middle'} centered style={{ marginBottom: '1em' }}>
            <Agent mobile={false} agent={this.state.newAgent} />
          </Grid>
            <div style={{ textAlign: 'center' }}>
              <Button type='submit' color='green' loading={loading}>Add new agent</Button>
            </div>
        </Form>
      </div>
    )
  }
}