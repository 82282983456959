import React, { Component } from 'react';
import { Button, Form, Header, Grid } from 'semantic-ui-react';
import Testimonial from '../../testimonials/Testimonial';

export default class newTestimonialEditor extends Component {
  state = {
    newTestimonial: {
      src: '', 
      buyers: '', 
      review: '',
      loading: false,
    },
  };

  submitAddnewTestimonial = async () => {
    const { newTestimonial } = this.state;
    newTestimonial.loading = true;
    this.setState({newTestimonial});

    const { src, buyers, review } = newTestimonial;
    try {
      await this.props.firestore.collection('testimonials').add({ src, buyers, review });
    } catch(e) {
      console.log(e);
    }

    this.setState({
      newTestimonial: {
        src: '', 
        buyers: '', 
        review: '',
        loading: false,
      }
    })
  }

  editNew = (e, key) => {
    const { newTestimonial } = this.state;
    newTestimonial[key] = e.target.value;
    this.setState({ newTestimonial });
  }

  render() {
    const { src, buyers, review, loading } = this.state.newTestimonial;
    return (
      <div>
        <Header
          as='h3'
          content={'New listing'}
        />
        <Form onSubmit={this.submitAddnewTestimonial}>
          <Form.Field>
            <label>Image URL</label>
            <input
              value={src}
              onChange={(e) => this.editNew(e, 'src')}
            />
          </Form.Field>
          <Form.Field>
            <label>Name(s)</label>
            <input
              value={buyers}
              onChange={(e) => this.editNew(e, 'buyers')}
            />
          </Form.Field>
          <Form.Field>
            <label>Review</label>
            <textarea
              value={review}
              onChange={(e) => this.editNew(e, 'review')}
            />
          </Form.Field>
          <Header
            as='h3'
            content={'How it will show up'}
          />
          <Grid verticalAlign={'middle'} centered style={{ marginBottom: '1em' }}>
            <Testimonial mobile={false} testimonial={this.state.newTestimonial} />
          </Grid>
            <div style={{ textAlign: 'center' }}>
              <Button type='submit' color='green' loading={loading}>Add new listing</Button>
            </div>
        </Form>
      </div>
    )
  }
}