import React, { Component } from 'react';
import { Button, Form, Header, Grid, Divider } from 'semantic-ui-react';
import FeaturedHome from '../../featuredHomes/FeaturedHome';

export default class ExistingListingsEditor extends Component {
  state = {
    featuredHomes: [],
  };

  componentDidMount() {
    this.refreshListings();
  }

  refreshListings = async () => {
    const listingCollection = await this.props.firestore.collection('featured_homes').get();

    const sortedListings = listingCollection.docs.sort((a, b) =>  a.data().status.localeCompare(b.data().status));
    
    const listings = sortedListings.map((listing) => ({
      id: listing.id,
      data: listing.data(),
    }));

    this.setState({featuredHomes: listings})
  }

  submitChanges = async (e, id) => {
    const { featuredHomes } = this.state;
    
    const listingIndex = featuredHomes.findIndex((home) => home.id === id);
    featuredHomes[listingIndex].loading = true;

    const listingInfo = featuredHomes[listingIndex];
    const { imageSrc, address, cityStateZip, price, listingUrl, status } = listingInfo.data;
    
    this.setState({featuredHomes});

    try {
      await this.props.firestore.collection('featured_homes').doc(id).set({
        imageSrc, address, cityStateZip, price, listingUrl, status
      });
    } catch(e) {
      console.log(e);
    }

    featuredHomes[listingIndex].loading = false;
    this.setState({featuredHomes});
  }

  edit = (event, id, key) => {
    const { featuredHomes } = this.state;
    const listingIndex = featuredHomes.findIndex((home) => home.id === id);
    featuredHomes[listingIndex].data[key] = event.target.value;
    this.setState({ featuredHomes });
  }

  deleteListing = async (e, id) => {
    const { featuredHomes } = this.state;
    const listingIndex = featuredHomes.findIndex((home) => home.id === id);
    featuredHomes[listingIndex].deleteLoading = true;
    this.setState({ featuredHomes });

    try {
      await this.props.firestore.collection('featured_homes').doc(id).delete();
      await this.refreshListings();
    } catch(e) {
      console.log(e);
    }
  }

  render() {
    const { featuredHomes } = this.state;
    const count = featuredHomes.length;
    if (count === 0) {
      return (
        <div>
          No listings have been added. Create a new one using the tab above.
        </div>
      )
    }
    return (
      featuredHomes.map((listing, index) => this.getHomeEditor(listing, index, count))
    )
  }

  getHomeEditor = (listingInfo, index, count) => {
    const { id, data, loading, deleteLoading } = listingInfo;
    const { imageSrc, address, cityStateZip, price, listingUrl, status } = data;
    return (
      <div key={id}>
        <Grid>
          <Grid.Column width={10}>
            <Header
              as='h3'
              content={'Listing ' + (index + 1) + " of " + count}
            />
            <Form onSubmit={(e) => this.submitChanges(e, id)}>
              <Form.Field>
                <label>Address</label>
                <input
                  value={address}
                  onChange={(e) => this.edit(e, id, 'address')}
                />
              </Form.Field>
              <Form.Field>
                <label>City, State, Zip</label>
                <input
                  value={cityStateZip}
                  onChange={(e) => this.edit(e, id, 'cityStateZip')}
                />
              </Form.Field>
              <Form.Field>
                <label>Price</label>
                <input
                  value={price}
                  onChange={(e) => this.edit(e, id, 'price')}
                />
              </Form.Field>
              <Form.Field>
                <label>Image URL</label>
                <input
                  value={imageSrc}
                  onChange={(e) => this.edit(e, id, 'imageSrc')}
                />
              </Form.Field>
              <Form.Field>
                <label>MLS Listing URL</label>
                <input
                  value={listingUrl}
                  onChange={(e) => this.edit(e, id, 'listingUrl')}
                />
              </Form.Field>
              <Form.Field>
                <label>Status</label>
                <input
                  value={status}
                  onChange={(e) => this.edit(e, id, 'status')}
                />
              </Form.Field>
              <Button type='submit' primary loading={loading}>Submit changes to listing</Button>
              <Button loading={deleteLoading} type="button" negative onClick={(e) => this.deleteListing(e, id)}>Delete listing</Button>
            </Form>
          </Grid.Column>
          <Grid.Column width={6}>
            <Header
              as='h3'
              content={'How it will show up'}
            />
            <FeaturedHome
              imageSrc={imageSrc}
              price={price}
              address={address}
              listingUrl={listingUrl}
              cityStateZip={cityStateZip}
              status={status}
            />
          </Grid.Column>
        </Grid>
        <Divider />
      </div>
    )
  }
}