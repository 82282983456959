import React, { Component } from 'react';
import { Tab } from 'semantic-ui-react';
import ExistingListingEditor from './ExistingTestimonialEditor';
import NewTestimonialEditor from './NewTestimonialEditor';

export default class TestimonialEditor extends Component {
  panes = [
    { 
      menuItem: 'Edit existing testimonials', 
      render: () => (
        <Tab.Pane>
          {<ExistingListingEditor firestore={this.props.firestore} />}
        </Tab.Pane>
      ) 
    },
    {
      menuItem: 'Add new testimonial',
      render: () => (
        <Tab.Pane>
          {<NewTestimonialEditor firestore={this.props.firestore} />}
        </Tab.Pane>
      )
    },
  ]

  render() {
    return (
      <div style={{padding: '2em 4em'}}>
        <Tab panes={this.panes} />
      </div>
    )
  }
}
