import React from 'react';
import { Icon, Button, Card, Image, Label } from 'semantic-ui-react';

const SoldLabel = ({status}) => (
  <Label color='green' size='big' ribbon>
    {status}
  </Label>
)

const FeaturedHome = ({ imageSrc, price, address, cityStateZip, listingUrl, status }) => {
  let imageSrcAttempt;
  try {
    imageSrcAttempt = new URL(imageSrc);
  } catch (e) {
    console.log("Invalid image src")
  }
  return (
  <Card fluid raised>
    {imageSrcAttempt 
      ? (
          <Image 
            src={imageSrcAttempt} 
            wrapped 
            ui={false}
            label={<SoldLabel status={status} />}
            className="center-cropped"
          />
        )
      : <div style={{margin: '1em 0', textAlign: 'center'}}>Invalid image source</div>
    }
    <Card.Content>
      <Card.Description>
        <p style={{ fontSize: '18px', fontWeight: 600}}>
          ${price}
        </p>
        <p>
          {address} <br/>
          {cityStateZip}
        </p>
      </Card.Description>
    </Card.Content>
    <Card.Content extra>
      <Button basic fluid color='green' icon 
        labelPosition='right' as='a' href={listingUrl}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon name='external alternate' />
        View on MLS
      </Button>
    </Card.Content>
  </Card>
)};

export default FeaturedHome;